import decode from "jwt-decode";
import router from "../../router";
import axios from "axios";

export const namespaced = true;

export const state = () => ({
  projects: [],
  project: {
		_id: '',
		user: '',
		title: '',
		description: '',
		status: 'SUMITTED',
		dataSet: [],
	},

});

export const mutations = {
  SET_PROJECTS(state, payload) {
    state.projects = payload;
  },
  SET_PROJECT(state, payload) {
    state.project = payload;
  },
};

export const actions = {
  async getProjects({ commit }) {
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.get('project/list', { headers: {"Token": token } });

      commit("SET_PROJECTS", response.data);
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    } finally {
      commit("SET_LOADER", false, { root: true });
    }
  },
  async addProject({ commit, dispatch }, data) {
    let me = this
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.post('project/add', {
        user: data.customer,
        title: data.title,
        description: data.description,
        status: data.status,
        dataSet: data.dataSet
      }, { headers: {"Token": token } } )
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: 'Project created successful',
        timeout: 4000
      }, {root: true})
      dispatch('getProjects')
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
  },
  async updateProject({ commit, dispatch }, data) {
    let me = this;
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.put('project/update', {
        _id: data._id,
        user: data.customer,
        title: data.title,
        description: data.description,
        status: data.status,
        dataSet: data.dataSet
      }, { headers: {"Token": token } })
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: 'Project updated successful',
        timeout: 4000
      }, {root: true})
      dispatch('getProjects')
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
  },
  async deleteProject({commit}, id) {
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.post('project/remove', {_id: id} ,{ headers: {"Token": token } })
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: 'Project deleted successful',
        timeout: 4000
      }, {root: true})
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
  },
	async removeFile({commit}, fileName){
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.post('project/remove-file', {
        fileName: fileName,
      }, { headers: {"Token": token } })
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: "file removed from disk",
        timeout: 3000
      }, {root: true})

    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
	},
	async updateDataSet({commit}, data){
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.put('project/update-dataset',
				{'_id': data.id, 'dataSet': data.dataSets},{ headers: {"Token": token } })
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: "file deleted from database",
        timeout: 4000
      }, {root: true})
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
	}
};

export const getters = {
	gettersDataSet( state ) {
		return state.project.dataSet;
	}
};
