<template>
  <v-app class="grey darken-2">

		<toolbar />

    <slot />

		<Footer />

		<v-snackbar
			absolute
			centered
			elevation="24"
			:timeout="alert.timeout"
			:color="alert.color"
			v-model="alert.show">
				{{ alert.message }}
		</v-snackbar>
  </v-app>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		 components: {
			 Footer: () => import('@/components/core/Footer'),
			 Toolbar: () => import('@/components/core/Toolbar')
		 },
		 computed: {
			...mapState(['alert']),
		 }

	}
</script>

<style lang="scss" scoped>

</style>