import decode from "jwt-decode";
import router from "../../router";
import axios from "axios";

export const namespaced = true;

export const state = () => ({
  users: [],
  user: {}
});

export const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  }
};

export const actions = {

  async getUser({ commit }, id) {
    let me = this;
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.get(`user/query?_id=${id}` , { headers: {"Token": token } })
      commit("SET_USER", response.data);
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    } finally {
      commit("SET_LOADER", false, { root: true });
    }

  },
  async getUsers({ commit }) {
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.get('user/list', { headers: {"Token": token } });
      commit("SET_USERS", response.data);
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})

    } finally {
      commit("SET_LOADER", false, { root: true });
    }

  },
  async addUser({ commit, dispatch }, data) {
    let me = this
		let fullName = `${data.name} ${data.lastName}`
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.post('user/add',
        {
          role: data.role,
          name: data.name,
          lastName: data.lastName,
          fullName: fullName,
          company: data.company,
          department: data.department,
          email: data.email,
          phone: data.phone,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zip,
          country: data.country,
          password: data.password
        },
        { headers: {"Token": token } }
      );
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: 'User created successful',
        timeout: 3000
      }, {root: true})
      dispatch('getUsers')
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
  },
  async updateUser({ commit, dispatch }, data) {
    let me = this;
		let fullName = `${data.name} ${data.lastName}`

    try {
      let token = await localStorage.getItem("token")
      let response = await axios.put('user/update',
        {
          _id: data._id,
          role: data.role,
          name: data.name,
          lastName: data.lastName,
          fullName: fullName,
          company: data.company,
          department: data.department,
          email: data.email,
          phone: data.phone,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zip,
          country: data.country,
          password: data.password,
        },
        { headers: {"Token": token } }
      );
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: 'User updated successful',
        timeout: 3000
      }, {root: true})
      dispatch('getUsers')
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
  },
  async deleteUser({commit}, id) {
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.post('user/remove',{_id: id},{ headers: {"Token": token } }
      );
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: 'User deleted successful',
        timeout: 3000
      }, {root: true})
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
  },
};

export const getters = {};
