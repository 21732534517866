import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { public: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
			admin: true,
		},
  },
  {
    path: '/user',
    name: 'UserView',
    component: () => import(/* webpackChunkName: "UserView" */ '../views/user/Index.vue'),
    meta: {
			admin: true,
		},
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: () => import(/* webpackChunkName: "UserProfile" */ '../views/user/Profile.vue'),
    meta: {
			admin: true,
		},
  },
  {
    path: '/project',
    name: 'ProjectView',
    component: () => import(/* webpackChunkName: "ProjectView" */ '../views/project/Index.vue'),
    meta: {
			admin: true,
		},
  },
  {
    path: '/test-model',
    name: 'TmodelView',
    component: () => import(/* webpackChunkName: "TmodelView" */ '../views/tmodel/Index.vue'),
    meta: {
			admin: true,
		},
  },
  {
    path: '/run/test-model/:id',
    name: 'RunTmodelView',
    component: () => import(/* webpackChunkName: "RunTmodelView" */ '../views/tmodel/TestForm.vue'),
    meta: {
			admin: true,
		},
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  store.dispatch("authModule/autoLogin");
  if (to.matched.some(record => record.meta.public)) {

    if ( store.state.authModule.user &&
				 store.state.authModule.user.role === 'ADMIN-ACIES'
			 ) {
      next({ name: "Dashboard" });
    } else {
      next();
    }

  } else if (store.state.authModule.user && store.state.authModule.user.role === 'ADMIN-ACIES') {
		if (to.matched.some((record) => record.meta.admin)) {
      next();
    }
  }  else {
    next({ name: 'Home' });
  }
});

export default router
