import Vue from 'vue'
import Vuex from 'vuex'
import * as authModule from './modules/auth'
import * as userModule from './modules/user'
import * as projectModule from './modules/project'
import * as tmodelModule from './modules/tmodel'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alert: {
      type: "success",
      show: false,
      message: "",
      color: "primary",
    },
    loading: false,
    dialog: false,
		editMode: 0, // 0 -> New 1 -> Edit
  },
  mutations: {
    SET_ALERT_MESSAGE: (state, data) => {
      state.alert.type = data.type;
      state.alert.show = data.show;
      state.alert.message = data.message;
      state.alert.color = data.color;
      setTimeout(() => {
        state.alert.type = "success";
        state.alert.show = false;
        state.alert.message = "";
        state.alert.color = "primary";
      }, data.timeout);
    },
    SET_LOADER(state, payload) {
      state.loading = payload
    },
    setDialog(state, payload) {
      state.dialog = payload
    },
		SET_EDITMODE(state, mode) {
			state.editMode = mode
		}
  },
  actions: {
		toggleMode({commit}, mode){
			commit('SET_EDITMODE', mode)
		}
	},
  modules: {
    authModule,
    projectModule,
    userModule,
    tmodelModule,
  }
})
