import decode from "jwt-decode";
import router from "../../router";
import axios from "axios";

export const namespaced = true;

export const state = () => ({
  tmodels: [],
  tmodel: {
		_id: '',
    title: '',
		project: '',
		projectId: '',
		modelId: '',
		variables: [],
    titleForm: '',
    subtitleForm: '',
    targetVarLabel: '',
    targetVarHint: '',
    runMax: 5,
    runAcum: 0
	},

});

export const mutations = {
  SET_TMODELS(state, payload) {
    state.tmodels = payload;
  },
  SET_TMODEL(state, payload) {
    state.tmodel = payload;
  },
};

export const actions = {
  async getTmodels({ commit }) {
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.get('tmodel/list', { headers: {"Token": token } });
      commit("SET_TMODELS", response.data);
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    } finally {
      commit("SET_LOADER", false, { root: true });
    }
  },
  async getModel({commit}, id){
    commit("SET_LOADER", true, { root: true });
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.get(`tmodel/query?_id=${id}`, { headers: {"Token": token } });
      commit("SET_TMODEL", response.data);

    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    } finally {
      commit("SET_LOADER", false, { root: true });
    }
  },
  async addTmodel({ commit, dispatch }, data) {
    data.runMax = ( data.runMax != null && data.runMax != '') ? data.runMax : 5
    data.runAcum = ( data.runAcum != null && data.runAcum != '') ? data.runAcum : 0
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.post('tmodel/add', {
        title: data.title,
        project: data.project,
        projectId: data.projectId,
        modelId: data.modelId,
        keyJson: data.keyJson,
        variables: data.variables,
        titleForm: data.titleForm,
        subtitleForm: data.subtitleForm,
        targetVarLabel: data.targetVarLabel,
        targetVarHint: data.targetVarHint,
        runMax: data.runMax,
        runAcum: data.runAcum
      }, { headers: {"Token": token } } )
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: 'Test model created successful',
        timeout: 4000
      }, {root: true})
      dispatch('getTmodels')
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
  },
  async updateTmodel({ commit, dispatch }, data) {
    let me = this;
    try {
      let token = await localStorage.getItem("token")
      data.runMax = ( data.runMax != null && data.runMax != '') ? data.runMax : 5
      data.runAcum = ( data.runAcum != null && data.runAcum != '') ? data.runAcum : 0

      let response = await axios.put('tmodel/update', {
        _id: data._id,
        title: data.title,
        project: data.project,
        projectId: data.projectId,
        keyJson: data.keyJson,
        modelId: data.modelId,
        variables: data.variables,
        titleForm: data.titleForm,
        subtitleForm: data.subtitleForm,
        targetVarLabel: data.targetVarLabel,
        targetVarHint: data.targetVarHint,
        runAcum: data.runAcum,
        runMax: data.runMax,
      }, { headers: {"Token": token } })
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: 'Test model updated successful',
        timeout: 4000
      }, {root: true})
      dispatch('getTmodels')
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
  },
  async deleteTmodel({commit}, id) {
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.post('tmodel/remove', {_id: id} ,{ headers: {"Token": token } })
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: 'Test model deleted successful',
        timeout: 4000
      }, {root: true})
    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
  },
  async removeFile({commit}, fileName){
    try {
      let token = await localStorage.getItem("token")
      let response = await axios.post('tmodel/remove-key', {
        fileName: fileName,
      }, { headers: {"Token": token } })
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'success',
        message: "file removed from disk",
        timeout: 3000
      }, {root: true})

    } catch (e) {
      let errorM = e.message
      console.log(errorM)
      commit('SET_ALERT_MESSAGE', {
        show: true,
        color: 'error',
        message: errorM,
        timeout: 4000
      }, {root: true})
    }
	},

};

export const getters = {};
